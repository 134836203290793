import NiceModal from "@ebay/nice-modal-react";
import SplunkOtelWeb from "@splunk/otel-web";
import SplunkSessionRecorder from "@splunk/otel-web-session-recorder";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactFlowProvider } from "@xyflow/react";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";

import Auth0ProviderWithHistory from "@components/authentication/Auth0ProviderWithHistory.tsx";
import AuthedElement from "@components/authentication/AuthedElement.tsx";
import ErrorHandler from "@components/util/ErrorHandler.tsx";

import App from "./App.tsx";
import "./index.scss";

const hostname = window.location.hostname;

let environment = "local";
if (hostname === "mosaic.magmutual.com") {
  environment = "prod";
} else if (hostname === "mosaic-dev.magmutual.com") {
  environment = "dev";
} else if (hostname === "mosaic-qa.magmutual.com") {
  environment = "qa";
} else if (hostname === "mosaic-uat.magmutual.com") {
  environment = "uat";
} else if (hostname.endsWith(".mag-core-tasks.pages.dev")) {
  environment = hostname.replace(".mag-core-tasks.pages.dev", "").toLowerCase();
}

// Public token
// https://docs.splunk.com/observability/en/rum/set-up-rum.html#rum-access-token
const SPLUNK_RUM_TOKEN = "sUXc63miAfNt6-SQbeAgRA"; // expires 7/18/2025
const SPLUNK_REALM = "us1";
SplunkOtelWeb.init({
  realm: SPLUNK_REALM,
  rumAccessToken: SPLUNK_RUM_TOKEN,
  applicationName: "lambda", // replace it with your application name(e.g. onemodel-ui)
  version: "0.0.0",
  deploymentEnvironment: environment, // environment where splunk should be initialized
});

SplunkSessionRecorder.init({
  realm: SPLUNK_REALM,
  rumAccessToken: SPLUNK_RUM_TOKEN,
});

const sendErrorNotification = (error) =>
  enqueueSnackbar(`An error occurred: ${error.message}`);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      //staleTime: 5 * 1000 * 60, // 5 minutes
      staleTime: Infinity,
      suspense: true,
    },
    mutations: {
      throwOnError: false,
      retry: 3,
    },
  },
  mutationCache: new MutationCache({
    onError: sendErrorNotification,
  }),
  queryCache: new QueryCache({
    onError: sendErrorNotification,
  }),
});
const rootElement = (
  <StrictMode>
    <ErrorHandler>
      <BrowserRouter>
        <RecoilRoot>
          <ReactFlowProvider>
            <Auth0ProviderWithHistory>
              <QueryClientProvider client={queryClient}>
                <NiceModal.Provider>
                  <SnackbarProvider
                    maxSnack={2}
                    className="top-of-everything"
                    preventDuplicate={true}
                    autoHideDuration={2000}
                    variant={"error"}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  />
                  <Routes>
                    <Route
                      path="/*"
                      element={<AuthedElement element={App} />}
                    ></Route>
                  </Routes>
                </NiceModal.Provider>
              </QueryClientProvider>
            </Auth0ProviderWithHistory>
          </ReactFlowProvider>
        </RecoilRoot>
      </BrowserRouter>
    </ErrorHandler>
  </StrictMode>
);
const root = ReactDOM.createRoot(document.getElementById("root")!).render(
  rootElement,
);
